<template>
  <div class="flex-1 flex flex-col overflow-hidden border bg-white border-gray-300 rounded">
  
    <POrderGrid
      ref="ordergrid"      
      transactionType="TO,HE,OO,GL,CB"
      :locationIdTo="location.id"
      :cols="cols"
      :filters="filters"
      :orderBy="orderBy"
      :expandAll="expandAll"      
      @click="onClick"
    ></POrderGrid>

    <portal to="filters">
      <POrderFilters v-model="filters" />
    </portal>

    <portal to="actions">
      <div class="ml-4 space-x-4 flex items-center">

        <PToggle 
          v-model="expandAll" 
          :label="$tk('Common.General.Detailed')" 
          :stacked="true" 
        />

        <PButton 
          color="primary"
          v-if="location.poolingGroupId || customer.hasPalletReceiptDays" 
          @click="createDialogVisible = true"           
        >
          <span v-html="$tk('Common.General.NewRegistration')"></span>
        </PButton>

      </div>
    </portal>    

    <CreateDialog 
      v-if="createDialogVisible" 
      @close="onCreateClose" 
    />

    <EditDialog 
      v-if="editDialogVisible" 
      @close="onEditClose" 
      :tab="editDialogStartTab"     
      :orderId="orderId" 
      view="reception"
    />

  </div>
</template>

<script>
import http from "@/http"
import { mapGetters } from "vuex"
import { format,  sub, add } from "date-fns"
import CreateDialog from "../dialogs/CreateDialog"
import EditDialog from "../dialogs/EditDialog"

export default {

  components: {
    CreateDialog,
    EditDialog
  },

  props: {
    id: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      cols: [        
        { text: this.$tk("Common.Order.OrderNo"), value: "id", sortable: true, component: "p-display-text", width: 110, xalign: "center", classes: "font-medium" },
        { text: this.$tk("Common.Order.Sent"), value: "dateSend", sortable: true, component: "p-display-date", width: 110, align: "center" },
        { text: this.$tk("Common.Order.Received"), value: "dateArrived", sortable: true, component: "p-display-date", width: 110, align: "center" },
        { text: this.$tk("Common.Order.AutoCommit"), value: "dateAutoCommit", sortable: true, component: "p-display-auto-commit", width: 130, align: "center" },
        { text: this.$tk("Common.Order.From"), sortable: true, component: "p-display-location", config: { direction: "From" }, align: "left" },
        { text: this.$tk("Common.Order.OrderRef"), value: "receiversRef", sortable:true, component: "p-display-text", align: "left" },
        { 
          nested: [            
            { text: this.$tk("Common.Order.RTI"), component: "p-display-product", componentConfig: { showTreatment: true }, aggregate: "p-display-product-images", align: "left", width: 220 },
            
            // { text: this.$tk("Common.Order.Ordered"), value: "quantityOrdered", component: "p-display-number", aggregate: "p-display-sum", width: 80, align: "right" },
            // { text: this.$tk("Common.Order.Picked"), value: "quantityPicked", component: "p-display-number", aggregate: "p-display-sum", width: 80, align: "right" },
            
            { text: this.$tk("Common.Order.Sent"), component: "p-display-sent", aggregate: "p-display-sum-sent", width: 80, align: "right" },

            { text: this.$tk("Common.Order.Received"), value: "quantityConfirmed", component: "p-display-number", aggregate: "p-display-sum", width: 80, align: "right" },
          ] 
        },
        { text: this.$tk("Common.Order.Comments"), value: "commentCount", component: "p-display-comments", width: 50, align: "center" },
        { text: this.$tk("Common.Order.Status"), component: "p-display-status", align: "center", width: 200 },
      ],      
      orderId: "",      
      expandAll: true,
      createDialogVisible: false,
      editDialogVisible: false,
      editDialogStartTab: "",
      filters: {
        term:             { value: "" },
        sendLower:        { value: format(sub(new Date(), { days: 21 }), "yyyy-MM-dd") },
        sendUpper:        { value: format(add(new Date(), { days: 7 }), "yyyy-MM-dd") },
        statuses: [
          { id: 1, value: false },
          { id: 2, value: true },
          { id: 4, value: true },
          { id: 6, value: true },
          { id: 5, value: false },
          { id: 12, value: true, visible: false },
          { id: 13, value: true, visible: false },
          { id: 14, value: true, visible: false },
        ]   
      },
      orderBy: {
        field: "id",
        descending: false
      },
    }
  },  

  computed: {
    ...mapGetters(["customer", "location"])
  },

  methods: {

    onClick ({ order, component }) {
      
      if (order.interpoolingCompany) return

      this.orderId = order.id
      this.editDialogStartTab = component === "p-display-comments" ? "comments" : ""
      this.editDialogVisible = true
      
    },

    onCreateClose () {
      this.createDialogVisible = false
      this.getOrders()
    },

    onEditClose () {
      this.editDialogVisible = false
      this.getOrders()
    },

    getOrders () {
      this.$refs.ordergrid.getOrders()     
    }
  },

  async created () {
    if (this.id) {
      const order = await http.get("Order", { params: { id: this.id }})
      this.onClick({ order })
    }
  }

}
</script>
